var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('H2',{staticStyle:{"text-align":"center"}},[_vm._v("Commodity Specifications")]),_c('b-table',{staticClass:"commodity-specs-table",staticStyle:{"border":"1px solid silver","width":"auto","font-size":"12px","margin":"0 -80px 0 13px","border-radius":"3px"},attrs:{"items":_vm.commoditySpecs,"fields":_vm.fields,"striped":"","sort-icon-left":""},scopedSlots:_vm._u([{key:"head(name)",fn:function(data){return [_c('b-form-input',{staticStyle:{"width":"150px","height":"10px","margin":"0px"},attrs:{"placeholder":"commodity name"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})]}},{key:"head()",fn:function(data){return [_c('span',{staticStyle:{"color":"black","background-color":"white"}},[_c('b',[_vm._v(_vm._s(data.label))])])]}},{key:"cell(symbol)",fn:function(ref){
var value = ref.value;
return [_c('b-row',[_c('b-col',{staticClass:"text-sm-center"},[_vm._v(_vm._s(value))])],1)]}},{key:"cell(new)",fn:function(ref){
var value = ref.value;
return [_c('b-row',[_c('b-col',{staticClass:"text-sm-center"},[_vm._v(_vm._s(value))])],1)]}},{key:"cell(ib)",fn:function(ref){
var value = ref.value;
return [_c('b-row',[_c('b-col',{staticClass:"text-sm-center"},[_vm._v(_vm._s(value))])],1)]}},{key:"cell(unitMove)",fn:function(ref){
var value = ref.value;
return [_c('b-row',[_c('b-col',{staticClass:"text-sm-right",staticStyle:{"margin":"0 5px 0 -24px"}},[_vm._v(_vm._s(value))])],1)]}},{key:"cell(units)",fn:function(ref){
var value = ref.value;
return [_c('b-row',[_c('b-col',{staticClass:"text-sm-center"},[_vm._v(_vm._s(value))])],1)]}},{key:"cell(tickValue)",fn:function(ref){
var value = ref.value;
return [_c('b-row',[_c('b-col',{staticClass:"text-sm-right",staticStyle:{"margin":"0 5px 0 -24px"}},[_vm._v(_vm._s(value))])],1)]}},{key:"cell(cot)",fn:function(ref){
var value = ref.value;
return [_c('b-row',[_c('b-col',{staticClass:"text-sm-center"},[_vm._v(_vm._s(value))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }