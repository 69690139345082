<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <template v-if="url()=='/builder'">
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">Program Info</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Program Info</span>
            </span>
          </li>

          <router-link
            to="/vue-bootstrap/alert"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <program-descriptions
                  v-bind:namespace="activeModuleName"
                ></program-descriptions>
                <!--/span-->
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      id="v-step-4"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-list-3"></i>
        <span class="menu-text">Favourites</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Favourites</span>
            </span>
          </li>

          <span style="margin: 0 0 0 0px">
            <v-icon
              v-if="selectedSidebarComponent === 'chartSaving'"
              id="v-step-4"
              style="color: #404040; margin-top: 4px"
              @click="showChartSavingClicked"
              >mdi-content-save-outline</v-icon
            >
            <saved-charts v-bind:namespace="activeModuleName"></saved-charts>
          </span>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="
        selectedSidebarComponent !== 'chartSharing' &&
        $store.state.user.role === 'superadmin'
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-telegram-logo"></i>
        <span class="menu-text">Share</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Share</span>
            </span>
          </li>

          <span v-if="selectedSidebarComponent === 'chartSharing'">
            <v-icon
              v-if="selectedSidebarComponent === 'chartSharing'"
              @click="showChartSharingClicked"
              style="color: #404040"
              >mdi-share-circle</v-icon
            >
            <div
              style="
                width: 90%;
                margin: -23px 8px 0px 16px;
                border: 0px solid darkgray;
                font-size: 1rem;
                text-align: left;
                color: rgb(7, 85, 163);
              "
            >
              <state-editor v-bind:namespace="activeModuleName"></state-editor>
            </div>
          </span>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="
        selectedSidebarComponent !== 'endpoint' 
         &&
        $store.state.user.role === 'superadmin'
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-analytics-2"></i>
        <span class="menu-text">Endpoints</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Endpoints</span>
            </span>
          </li>

          <span>
            <v-icon
              v-if="
                selectedSidebarComponent === 'endpoint' 
              "
              v-b-tooltip.hover.topleft="'endpoint'"
              @click="showEndpointClicked"
              style="color: #404040"
              >mdi-access-point</v-icon
            >
          </span>
          <endpoint-selector
            style="margin: 3px 10px 30px 0px"
          ></endpoint-selector>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="
        selectedSidebarComponent !== 'endpoint'
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-dashboard"></i>
        <span class="menu-text">Settings</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Settings</span>
            </span>
          </li>

          <span style="text-align: center">
            <v-icon
              v-if="selectedSidebarComponent === 'settings'"
              v-b-tooltip.hover.topleft="'settings'"
              @click="showSettingsClicked"
              style="color: #404040; margin-bottom: 8px"
              >mdi-cog-outline</v-icon
            >
            <settings-panel></settings-panel>
          </span>
        </ul>
      </div>
    </li>

    </template>
  </ul>
</template>

<script>
import * as fb from "@/firebase";
// import savedChartsSearch from "@/components/saved-charts-search.vue";
const StateEditor = () => import("@/components/state-editor");
import endpointSelector from "@/components/endpoint-selector";
import settingsPanel from "@/components/settings-panel";
import savedCharts from "@/components/saved-charts.vue";
import programDescriptions from "@/components/program-descriptions.vue";

export default {
  name: "KTMenu",
  components: {
    StateEditor,
    endpointSelector,
    settingsPanel,
    savedCharts,
    programDescriptions,
  },
  mounted() {
    // console.log("mounted() starting. this.namespace=", this.namespace);
    this.userEmail = this.$store.state.user.email;
    // this.updateSelectedSidebarComponentToFirebase();

    // console.log("this.selectedSidebarComponent=", this.selectedSidebarComponent);
    if (this.selectedSidebarComponent !== null) {
      this.mini = false;
    }
  },
  props: {
    namespace: {
      type: String,
    },
    defaultAddTreeNodeTitle: {
      type: String,
      default: "Add Folder",
    },
  },
  data: function () {
    return {
      json: "",
      saveName: "",
      hide: "hide",
      show: "show",
      saveShow: true,
      savedCharts: [],
      selectedDisplayOptions: ["tree"],
      // layout: [],
      oldName: "",
      newTree: {},
      data: null,
      initialized: false,
      users: [],
      userEmail: "",
      drawer: true,
      mini: true,
      sidebarOptions: [
        { value: "getStarted", text: "Program Description" },
        { value: "chartSaving", text: "Saved Tabs" },
        { value: "chartSharing", text: "Chart Sharing" },
        { value: "endpoint", text: "Endpoint" },
        { value: "settings", text: "Settings" },
      ],
    };
  },

  firestore: {
    users: fb.usersCollection,
  },
  computed: {
    selectedSidebarTitle() {
      // console.log("selectedSidebarTitle() starting. this.selectedSidebarComponent=", this.selectedSidebarComponent);
      let option = this.sidebarOptions.find(
        (x) => x.value === this.selectedSidebarComponent
      );
      // console.log("option=", option);
      return typeof option !== "undefined" ? option.text : "";
    },
    usersOptions() {
      return this.users.map((x) => x.email);
    },
    email() {
      // console.log("this.$store.state.user.email=", this.$store.state.user.email);
      return this.$store.state.user.role === "superadmin"
        ? this.userEmail
        : this.$store.state.user.email;
    },
    selectedSidebarComponent: {
      get() {
        return this.$store.state.user.selectedSidebarComponent;
      },
      set() {
        // console.log("selectedSidebarComponent set() starting.");
      },
    },
    program() {
      // console.log("program=", this.$store.state[this.namespace].program);
      return typeof this.$store.state[this.namespace] === "undefined" ||
        typeof this.$store.state[this.namespace].program === "undefined"
        ? "Tabs"
        : this.$store.state[this.namespace].program;
    },
    activeModuleName() {
      let activeModuleName = this.$store.getters["activeModuleName"];
      // console.log("activeModuleName=", activeModuleName);
      return activeModuleName;
    },
  },
  watch: {
    selectedSidebarComponent: function (selectedSidebarComponent) {
      console.log("watch selectedSidebarComponent=", selectedSidebarComponent);
      if (selectedSidebarComponent !== null) {
        this.mini = false;
        this.selectedSidebarComponent = selectedSidebarComponent;
      } else {
        this.mini = true;
        this.selectedSidebarComponent = null;
      }
    },
  },
  methods: {
    url() {
       console.log("Path -->" + this.$route.path);
      return this.$route.path;
    },
    hasActiveChildren(match) {
       console.log("hasActiveChildren() starting.");
      return this.$route["path"].indexOf(match) !== -1;
    },
    toggleSidebar() {
      console.log("toggleSidebar() starting.");
      if (this.mini === false) {
        this.selectedSidebarComponent = null;
        this.updateSelectedSidebarComponentToFirebase(null);
        this.mini = true;
      } else {
        this.mini = false;
      }
    },
    showSidebarComponentClicked(component) {
      console.log(
        "showSidebarComponentClicked() starting. component=",
        component
      );
      let newSelectedSidebarComponent;
      if (this.selectedSidebarComponent === component) {
        newSelectedSidebarComponent = null;
        this.mini = true;
      } else {
        newSelectedSidebarComponent = component;
      }
      this.updateSelectedSidebarComponentToFirebase(
        newSelectedSidebarComponent
      );
    },
    showGetStartedClicked() {
      this.showSidebarComponentClicked("getStarted");
    },
    showChartSharingClicked() {
      console.log("showChartSharingClicked() starting.");
      this.showSidebarComponentClicked("chartSharing");
    },
    showChartSavingClicked() {
      console.log("showChartSavingClicked() starting.");
      this.showSidebarComponentClicked("chartSaving");
    },
    showEndpointClicked() {
      console.log("showEndpointClicked() starting.");
      this.showSidebarComponentClicked("endpoint");
    },
    showSettingsClicked() {
      console.log("showSettingsClicked() starting.");
      this.showSidebarComponentClicked("settings");
    },
    updateSelectedSidebarComponentToFirebase(component) {
      console.log(
        "updateSelectedSidebarComponentToFirebase() starting. component=",
        component
      );
      this.selectedSidebarComponent = component;
      fb.usersCollection
        .doc(this.email)
        .update({ selectedSidebarComponent: component })
        .then(() => {
          console.log("selectedSidebarComponent updated.");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
  },
};
</script>
