<template>
  <div class="topbar-item" style="position: absolute;top: -3px;right: 22px;display: flex">
        
       <b-button  class="btn dropdown-toggle" 
            v-if="['admin', 'superadmin'].includes($store.state.user.role)"
            v-on:click="startTour"
            style="color: white; background-color: #9A0A54;width: auto; height: 35px; margin: 0px 15px 0 0px;"
            >Tour</b-button>

        <b-dropdown style="width: auto; height: 35px; margin: 0px 15px 0 0px;" variant="primary" class="my-class">
            <template #button-content>
              <span>Commodities</span>
            </template>
            <table border=0 style="width: 1200px; margin: 0 5px 5px 5px;">
                <tr>
                    <td style="padding: 0 0 0 20px;">
                        <commodity-specs-table></commodity-specs-table>
                    </td>
                    <td style="vertical-align: top; padding: 0 0 0 -50px">
                        <months-table></months-table>
                    </td>
                </tr>
            </table>
        </b-dropdown>
        <div
              v-if="['admin', 'superadmin'].includes($store.state.user.role)"
              class="topbar-item"
              style="margin: -2px 0px 0px 10px;"
            >
              <Icon v-bind:style="{color: $store.state.siteData.portfolioAlarmTriggered ? 'red' : $store.state.siteData.portfolioAlarmToNotify ? 'green' : 'black'}"
                    icon="mdi:bell-alert-outline" width="27" height="27"
                    v-b-tooltip.noninteractive.hover.topleft="'portfolio alarm triggered'" />

              <b-dropdown
                size="sm"
                variant="link"
                toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
                no-caret
                right
                no-flip
              >
                <template v-slot:button-content>
                  <img
                    class="h-20px w-20px rounded-sm"
                    :src="languageFlag || getLanguageFlag"
                    alt=""
                  />
                </template>
                <b-dropdown-text tag="div" class="min-w-md-175px">
                  <KTDropdownLanguage
                    v-on:language-changed="onLanguageChanged"
                  ></KTDropdownLanguage>
                </b-dropdown-text>
              </b-dropdown>
            </div>
    <div
      class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span class="text-muted font-weight-bold font-size-base d-md-inline mr-1">
        {{ $t('hello', { msg: 'hello' }) }} , 
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-md-inline mr-3"
      >
        {{ name == undefined ? email : name }}
      </span>
      <span class="symbol symbol-25 symbol-light-primary">
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{
            name == undefined
              ? email.charAt(0).toUpperCase()
              : name.charAt(0).toUpperCase()
          }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <span style="font-size: 12px; position: absolute; top: 5px; right: 5px"
        >Version: BETA_1.0.0_11032023</span
      >
      <div
        class="
          offcanvas-header
          d-flex
          align-items-center
          justify-content-between
          pb-5
        "
      >
        <h3 class="font-weight-bold m-0">
          Profilo Utente 2
          <!--small class="text-muted font-size-sm ml-2">12 messages</small-->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
       <div class="font-weight-bold h5">
        <h6  class="font-weight-bold m-0" style="margin-bottom: 8px">Version:&nbsp;v. 2.0.0 (20230503)</h6>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="d-flex flex-column">
            <router-link
              to="/custom-pages/profile"
              class="
                font-weight-bold font-size-h5
                text-dark-75 text-hover-primary
              "
            >
              {{ name }}
            </router-link>
            <!--div class="text-muted mt-1">Application Developer</div-->
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <!--end:Item-->

        <!--begin::Item-->
        <div class="navi navi-spacer-x-0 p-0">
          <router-link
            to="/"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Profile</div>
                <div class="text-muted">Accesso Area Utente</div>
              </div>
            </div>
          </router-link>
        </div>
        <!--end:Item-->      


        <!--begin::Item-->
        <div class="navi navi-spacer-x-0 p-0">
          <router-link
            to="/"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/Communication/Mail-opened.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">ChartsLab</div>
                <div class="text-muted">Accesso Applicazione</div>
              </div>
            </div>
          </router-link>
        </div>
        <!--end:Item-->
        <div
          v-if="role == 'admin' || role == 'superadmin'"
          class="navi navi-spacer-x-0 p-0"
        >
         <router-link>
          <!--begin::Item-->
          <div class="navi-link">
            <div class="navi-text" style="display: inline-flex">
              <div class="font-weight-bold h5">
                <button class="btn btn-light-success btn-bold h4" onClick="window.open('https://billing.stripe.com/p/login/cN2eWudEZ5cs1kk4gg', '_blank')">MANAGE YOUR SUBSCRIPTION</button>
              </div>
            </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/admin"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-warning">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Admin</div>
                <div class="text-muted">Users Management</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/builder"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
            v-if="false"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Files/Selected-file.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Activities</div>
                <div class="text-muted">Logs and notifications</div>
              </div>
            </div>
          </router-link>
        </div>

        <!--end::Nav-->
        <div class="separator separator-dashed my-7" v-if="false"></div>
        <!--begin::Notifications-->
        <div v-if="false">
          <!--begin:Heading-->
          <h5 class="mb-5">Recent Notifications</h5>
          <!--end:Heading-->
          <template v-for="(item, i) in list">
            <!--begin::Item -->
            <div
              class="d-flex align-items-center rounded p-5 gutter-b"
              v-bind:class="`bg-light-${item.type}`"
              v-bind:key="i"
            >
              <span
                class="svg-icon mr-5"
                v-bind:class="`svg-icon-${item.type}`"
              >
                <span class="svg-icon svg-icon-lg">
                  <!--begin::Svg Icon-->
                  <inline-svg :src="item.svg" />
                  <!--end::Svg Icon-->
                </span>
              </span>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a
                  href="#"
                  class="
                    font-weight-normal
                    text-dark-75 text-hover-primary
                    font-size-lg
                    mb-1
                  "
                >
                  {{ item.title }}
                </a>
                <span class="text-muted font-size-sm">
                  {{ item.desc }}
                </span>
              </div>
              <span
                class="font-weight-bolder py-1 font-size-lg"
                v-bind:class="`text-${item.type}`"
              >
                {{ item.alt }}
              </span>
            </div>
            <!--end::Item -->
          </template>
        </div>
        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn.btn-primary.dropdown-toggle:after {
    color: #FFFFFF;
}
#kt_quick_user {
  overflow: hidden;
}
</style>
<style>
.program-select {
  background: #05386b
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px !important;
}

.tooltip {
    z-index: 99999;
    font-size: 13px;
}

.tooltip-inner {
    text-align: left;
}

.dropdown-menu {
    margin: 10px 20px 0 -450px;
    max-height: 600px;
    overflow-y: auto;
  }
  .btn.btn-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) {
    color: #FFFFFF;
    background-color: #650536;
    border-color: #650536;
}
.btn.btn-secondary.dropdown-toggle:after {
    color: #FFFFFF !important;
}
</style>

<script>
import i18nService from "@/core/services/i18n.service.js";
import { Icon } from '@iconify/vue2';
import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import commoditySpecsTable from '@/components/commodity-specs-table';
import monthsTable from '@/components/months-table';
import * as fb from '@/firebase';

export default {
    components: {
      KTDropdownLanguage,
      Icon,
      commoditySpecsTable,
      monthsTable
  },
  name: "KTQuickUser",
  data() {
    return {
      language: "en",
      languages: i18nService.languages,
      languageOptions: [
        { value: "de", text: "DE" },
        { value: "en", text: "EN" },
      ],
      list: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning",
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success",
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger",
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info",
        },
      ],
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    startTour() {
      console.log("startTour() starting. this.showTour=", this.showTour);
      this.$tours["myTour"].start();

      fb.usersCollection
        .doc(this.email)
        .update({ showTour: false })
        .then(() => {
          console.log("showTour updated.");
        });
    },
    onLanguageChanged() {
     // console.log("this.languages=", this.languages);
     // console.log("i18nService.getActiveLanguage()=", i18nService.getActiveLanguage());

      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    selectedLanguage(lang) {
      console.log("selectedLanguage() starting. lang=", lang);

      i18nService.setActiveLanguage(lang);

      this.$emit(
        "language-changed",
        this.languages.find((val) => {
          return val.lang === lang;
        })
      );

      // window.location.reload();
    },
    onLogout() {
      this.$store.dispatch("user/logout");
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    email() {
      return this.$store.state.user.email;
    },
    name() {
      return this.$store.state.user.name;
    },
    role() {
      return this.$store.state.user.role;
    },
    activeLanguage() {
      let activeLanguage = i18nService.getActiveLanguage();
      console.log("activeLanguage=", activeLanguage);
      return activeLanguage;
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
  },
};
</script>
