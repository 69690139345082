<template>
  <div class="d-flex flex-column flex-root" style="overflow: hidden scroll;">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content 
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid" 

        >-->
        <div id="kt_content" class="d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid" style="margin: 0px -25px;">
            <div style="margin-top: 10px;"
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
              
            >
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
        <v-tour v-if='["admin", "superadmin"].includes($store.state.user.role)' name="myTour" :steps="steps" :options="{ debug: true }"></v-tour>
        <KTFooter></KTFooter>
      </div>
    </div>
    <!--KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar
    <KTScrollTop></KTScrollTop> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
//import KTSubheader from "@/view/layout/subheader/Subheader.vue";
//import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
//import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    //KTSubheader,
    //sKTStickyToolbar,
    //KTScrollTop,
    Loader,
  },
  beforeMount() {
    // show page loading

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted: function () {
     if(["admin", "superadmin"].includes(this.$store.state.user.role) && this.showTour){
       console.log("starting tour.");
        this.$tours['myTour'].start();

     fb.usersCollection
       .doc(this.$store.state.user.email)
       .update({"showTour": false})
       .then(() => {
          console.log('showTour updated.')
      }); 
     }
  },
  methods: {},
  data: function() {
      let that = this;

      return {
        steps: [
          {
            target: '#v-step-0',  // We're using document.querySelector() under the hood
            header: {
              title: 'Get Started',
            },
            content: `Take a brief tour.`,
            params: {
              enableScrolling: false
            }
          }, 
          {
            target: '#v-step-1',
            content: 'Click this to see information about the current tab.',
            params: {
              placement: 'right',
              enableScrolling: false
            },
             /*before:  function(){ 
                        return new Promise((resolve) => {
                          // Time-consuming UI/async operation here.
                          console.log("that.$refs.content.$refs.tabsBox.$refs.savedCharts=", that.$refs.content.$refs.tabsBox.$refs.savedCharts);

                          that.$refs.content.$refs.tabsBox.$refs.savedCharts.mini = false;
                          that.$refs.content.$refs.tabsBox.$refs.savedCharts.selectedSidebarComponent = "getStarted";
                          resolve('foo');
                        });
                      }*/
          },
          {
            target: '#v-step-2',
            content: 'Click here to add a tab.',
            params: {
                placement: 'top', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                enableScrolling: false
              }
          },
          {
            target: '#v-step-3',
            content: 'Click here to close the active tab.',
            params: {
                placement: 'right', 
                enableScrolling: false
              }/*,
            before:  function(){ 
                        return new Promise((resolve) => {
                          // Time-consuming UI/async operation here.

                          bus.$emit('newTab', "BasicCharts");
                          resolve('foo');
                        });
                      }*/
          },
          {
            target: '#v-step-4',  // We're using document.querySelector() under the hood
            content: `Save tabs here.`,
            params: {
                      placement: 'right',
                      enableScrolling: false
            },
            /*before:  function(){
                        return new Promise((resolve) => {
                          // Time-consuming UI/async operation here.
                          console.log("that.$refs.content.$refs.tabsBox.$refs.savedCharts=", that.$refs.content.$refs.tabsBox.$refs.savedCharts);

                          that.$refs.content.$refs.tabsBox.$refs.savedCharts.mini = false;
                          that.$refs.content.$refs.tabsBox.$refs.savedCharts.selectedSidebarComponent = "chartSaving";
                          resolve('foo');
                        });
                      }*/
          }
        ]
      }
  },
  computed: {
    showTour(){
      return this.$store.state.user.showTour;
    },
    year(){
      return moment().format("YYYY");
    },
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return true;
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
