<template>
<div>
    <!-- begin:: Header -->
    <div
      id="kt_header"
      ref="kt_header"
      class="header"
      v-bind:class="headerClasses"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-start"
        style="padding-left: 0px; border-radius: 10px;"
      >
        <!-- begin:: Header Menu 
        <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
          style="position: absolute;left: 0px;"
        >-->
        <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
        >
          <div
            v-if="headerMenuEnabled"
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <KTMenu></KTMenu>
          </div>
        </div>
        <!-- end:: Header Menu -->

          
            
            <!--b-nav-item
              v-if="!['admin', 'superadmin'].includes($store.state.user.role)"
              disabled
              id="popover-target-1"
              right
              style="margin: -1px 10px 0 10px; font-size: 1.3rem; color: white: position: absolute"
              >Lang
            </b-nav-item-->
  <!--begin: Language bar -->

          
            <!--div
              v-if="['admin', 'superadmin'].includes($store.state.user.role)"
              class="topbar-item"
              style="margin: -2px 0px 0px 10px;position: absolute;top: 0px;right: 200px;"
            >
              <Icon v-bind:style="{color: $store.state.siteData.portfolioAlarmTriggered ? 'red' : $store.state.siteData.portfolioAlarmToNotify ? 'green' : 'black'}"
                    icon="mdi:bell-alert-outline" width="27" height="27"
                    v-b-tooltip.noninteractive.hover.topleft="'portfolio alarm triggered'" />

              <b-dropdown
                size="sm"
                variant="link"
                toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
                no-caret
                right
                no-flip
              >
                <template v-slot:button-content>
                  <img
                    class="h-20px w-20px rounded-sm"
                    :src="languageFlag || getLanguageFlag"
                    alt=""
                  />
                </template>
                <b-dropdown-text tag="div" class="min-w-md-175px">
                  <KTDropdownLanguage
                    v-on:language-changed="onLanguageChanged"
                  ></KTDropdownLanguage>
                </b-dropdown-text>
              </b-dropdown>
            </div-->
            <!--end: Language bar -->
            <KTQuickUser></KTQuickUser>
        <!--  <KTTopbar></KTTopbar>-->
      </div>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
//import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";
import { programCompositionArray } from "@/js/main";
import { bus } from "@/main";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
//import { StripeCheckout } from "@vue-stripe/vue-stripe";
import i18nService from "@/core/services/i18n.service.js";
//import loadingIndicator from "@/components/loading-indicator";
const STRIPE_PK_KEY = "";
const ENV = window.location.protocol + "//" + window.location.host;
//import { Icon } from '@iconify/vue2';

//import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import * as fb from "@/firebase";

export default {
  name: "KTHeader",
  components: {
  //  KTTopbar,
    KTMenu,
    KTQuickUser,
    //StripeCheckout,
    //loadingIndicator,
    //KTDropdownLanguage,
    //Icon
  },
    data() {
    return {
      oldProgram: null,
      publishableKey: STRIPE_PK_KEY,
      languageFlag: "",
      paymentIconClasses: "",
      loading: false,
      isHidden: false,
      monthlyRecurringLineItem: [
        {
          price: "price_1KyN0yHENkNdfsciaF0UtK0X",
          quantity: 1,
        },
      ],
      paymentStatusText: "",
      yearlyRecurringLineItem: [
        {
          price: "price_1KyN1JHENkNdfsciHFWVSFpd",
          quantity: 1,
        },
      ],
      allowPromotionCodes: true,
      billingAddressCollection: "required",
      successURL: window.location.href + "?paymentStatus=success",
      cancelURL: window.location.href + "?paymentStatus=cancel",
      language: "en",
      languages: i18nService.languages,
      languageOptions: [
        { value: "de", text: "DE" },
        { value: "en", text: "EN" },
      ],
    };
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
        this.oldProgram = this.program;
    let paymentStatus = this.$route.query.paymentStatus;
    if (paymentStatus != null) {
      if (paymentStatus == "success") {
        this.$refs["paymentStatusPopup"].show();
        this.paymentStatusText = "Payment Successful.";
        this.paymentIconClasses = "flaticon2-check-mark text-success";
      } else if (paymentStatus == "cancel") {
        this.$refs["paymentStatusPopup"].show();
        this.paymentStatusText = "Payment Failed.";
        this.paymentIconClasses = "flaticon2-cross text-danger";
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  email() {
      return this.$store.state.user.email;
    },
    activeModuleName() {
      let activeModuleName = this.$store.getters["activeModuleName"];
      // console.log("activeModuleName=", activeModuleName);
      return activeModuleName;
    },
    programOptions() {
      let options = programCompositionArray(this.$store.state.user.role);
     // console.log("options=", options);
      if (this.oldProgram === null) {
        options.unshift({ value: null, text: "Select Program" });
      }
      return options;
    },
    program: {
      get() {
        // console.log("program() starting.");
        let activeModuleIndex = this.$store.state.activeModuleIndex;
        // console.log("activeModuleIndex=", activeModuleIndex);
        let program = null;
        let moduleArray = this.$store.getters["moduleArray"];
        // console.log("moduleArray=", moduleArray);
        if (typeof moduleArray[activeModuleIndex] !== "undefined") {
          program = moduleArray[activeModuleIndex].program;
        }
        // console.log("program=", program);
        return program;
      },
      set() {},
    },
    showStateEditor() {
      return this.$store.state.showStateEditor;
    },
    showTour() {
      return this.$store.state.user.showTour;
    },
    activeLanguage() {
      let activeLanguage = i18nService.getActiveLanguage();
      console.log("activeLanguage=", activeLanguage);
      return activeLanguage;
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
  },
  watch: {
    program: function(program) {
      // console.log("watch showPlaybackControl=", showPlaybackControl);
      this.oldProgram = program;
    },
  },
  methods: {
    onLanguageChanged() {
     // console.log("this.languages=", this.languages);
     // console.log("i18nService.getActiveLanguage()=", i18nService.getActiveLanguage());

      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    selectedLanguage(lang) {
      console.log("selectedLanguage() starting. lang=", lang);

      i18nService.setActiveLanguage(lang);

      this.$emit(
        "language-changed",
        this.languages.find((val) => {
          return val.lang === lang;
        })
      );

      // window.location.reload();
    },
    startTour() {
      console.log("startTour() starting. this.showTour=", this.showTour);
      this.$tours["myTour"].start();

      fb.usersCollection
        .doc(this.email)
        .update({ showTour: false })
        .then(() => {
          console.log("showTour updated.");
        });
    },
    hideModal() {
      this.$refs["paymentStatusPopup"].hide();
      this.$router.push(this.$route.path);
    },
    upgradePackage() {
      this.$refs["upgradePopup"].show();
    },
    monthlyRecurring() {
      this.isHidden = true;
      window.location.href =
        "https://app.charts.alpha4all.it/sessionStripe.php?priceId=price_1KyN0yHENkNdfsciaF0UtK0X&env=" +
        ENV +
        "&email=" +
        this.email;
    },
    yearlyRecurring() {
      this.isHidden = true;
      window.location.href =
        "https://app.charts.alpha4all.it/sessionStripe.php?priceId=price_1KyN1JHENkNdfsciHFWVSFpd&env=" +
        ENV +
        "&email=" +
        this.email;
      //this.$refs.yearlyRecurringCheckoutRef.redirectToCheckout();
    },
    onLogout() {
      this.$store.dispatch("user/logout");
    },
    toggleStateEditor() {
      this.$store.commit("setShowStateEditor", !this.showStateEditor);
    },
    changeProgram(program) {
      console.log("changeProgram() starting. program=", program);
      this.oldProgram = this.program;
      bus.$emit("changeTab", program);
    },
  }

};
</script>

<style>
.program-select {
  background: #05386b
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px !important;
}
</style>

